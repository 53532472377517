/*
 * @Author: xuhuang xuhuang@wedobest.com.cn
 * @Date: 2022-09-21 14:48:49
 * @LastEditors: fangheng fangheng@wedobest.com.cn
 * @LastEditTime: 2023-07-24 17:44:38
 * @FilePath: index.ts
 * @Description:
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import { useStore } from '@/store'

const routes = [
  {
    path: '/login',
    redirect: '/',
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home/index.vue'),
    meta: { title: '首页' },
    redirect: '/incoming',
    children: [
      {
        path: '/rolePermission',
        name: 'rolePermission',
        component: () => import('@/views/permissionManage/rolePermission.vue'),
        meta: { title: '角色权限', pathName: '权限管理', parent: 'permissionManage' },
      },
      {
        path: '/userPermission',
        name: 'userPermission',
        component: () => import('@/views/permissionManage/userPermission.vue'),
        meta: { title: '用户权限', pathName: '权限管理', parent: 'permissionManage' },
      },
      {
        path: '/appPermission',
        name: 'appPermission',
        component: () => import('@/views/permissionManage/appPermission.vue'),
        meta: { title: '应用权限', pathName: '权限管理', parent: 'permissionManage' },
      },
      {
        path: '/incoming',
        name: 'incoming',
        component: () => import('@/views/revenueKanbanManage/index.vue'),
        meta: { title: '收入报表', pathName: '收入看板', parent: 'revenueKanban' },
      },
      {
        path: '/monitor',
        name: 'monitor',
        component: () => import('@/views/monitor/index.vue'),
        meta: { title: '监控看板', pathName: '收入看板', parent: 'revenueKanban' },
      },
      {
        path: '/accountManage',
        name: 'accountManage',
        component: () => import('@/views/payManage/accountManage'),
        meta: { title: '主体管理', pathName: '支付管理', parent: 'payManage' },
      },
      {
        path: '/newAccountManage',
        name: 'newAccountManage',
        component: () => import('@/views/payManage/newAccountManage'),
        meta: { title: '账号管理', pathName: '支付管理', parent: 'payManage' },
      },
      {
        path: '/appManage',
        name: 'appManage',
        component: () => import('@/views/payManage/appManage'),
        meta: { title: '产品管理', pathName: '支付管理', parent: 'payManage' },
      },
      {
        path: '/goodsSetting',
        name: 'goodsSetting',
        component: () => import('@/views/goodsManage/goodsSetting'),
        meta: { title: '商品配置', pathName: '商品管理', parent: 'goodsManage' },
      },
      {
        path: '/goodsSettingDetail',
        name: 'goodsSettingDetail',
        component: () => import('@/views/goodsManage/goodsSetting/detail'),
        meta: { title: '商品配置详情', pathName: '商品管理', parent: 'goodsManage' },
      },
      {
        path: '/goodsJudge',
        name: 'goodsJudge',
        component: () => import('@/views/goodsManage/goodsJudge'),
        meta: { title: '商品审核', pathName: '商品管理', parent: 'goodsManage' },
      },
      {
        path: '/goodsInfo',
        name: 'goodsInfo',
        component: () => import('@/views/goodsManage/goodsInfo'),
        meta: { title: '商品信息', pathName: '商品管理', parent: 'goodsManage' },
      },
      {
        path: '/logManage',
        name: 'logManage',
        component: () => import('@/views/logManage'),
        meta: { title: '操作日志', pathName: '日志管理', parent: 'logManage' },
      },
      {
        path: '/orderList',
        name: 'orderList',
        component: () => import('@/views/orderManage/orderList'),
        meta: { title: '订单查询', pathName: '订单管理', parent: 'orderManage' },
      },
      {
        path: '/blackList',
        name: 'blackList',
        component: () => import('@/views/orderManage/blackList'),
        meta: { title: '黑名单配置', pathName: '订单管理', parent: 'orderManage' },
      },
      {
        path: '/propsGivenAwayList',
        name: 'propsGivenAwayList',
        component: () => import('@/views/propsGivenAway/index.vue'),
        meta: { title: '道具赠送', pathName: '道具赠送', parent: 'propsGivenAwayList' },
      },
      {
        path: '/paidManage',
        name: 'paidManage',
        component: () => import('@/views/paidDownloads/paidManage/index.vue'),
        meta: { title: '付费产品管理', pathName: '付费下载', parent: 'paidDownloadsManage' },
      },
      {
        path: '/paidKanban',
        name: 'paidKanban',
        component: () => import('@/views/paidDownloads/paidKanban/index.vue'),
        meta: { title: '付费下载看板', pathName: '付费下载', parent: 'paidDownloadsManage' },
      },
      {
        path: '/thirdPartyBill',
        name: 'thirdPartyBill',
        component: () => import('@/views/thirdPartyBill/index.vue'),
        meta: { title: '三方账单', pathName: '收入看板', parent: 'revenueKanban' },
      },
      {
        path: '/thirdPartyBillDetail',
        name: 'thirdPartyBillDetail',
        component: () => import('@/views/thirdPartyBill/thirdPartyDetail/index.vue'),
        meta: { title: '三方账单', pathName: '收入看板', parent: 'revenueKanban' },
      },
      {
        path: '/systemNotification',
        name: 'systemNotification',
        component: () => import('@/views/systemNotification/index.vue'),
        meta: { title: '系统通知', pathName: '系统通知', parent: 'logManage' },
      },
      {
        path: '/systemNotificationDetail',
        name: 'systemNotificationDetail',
        component: () => import('@/views/systemNotification/systemNotificationDetail/index.vue'),
        meta: { title: '系统通知', pathName: '系统通知', parent: 'logManage' },
      },
      {
        path: '/systemNotification/notificationList',
        name: 'notificationList',
        component: () => import('@/views/systemNotification/notificationList/index.vue'),
        meta: { title: '系统通知', pathName: '系统通知', parent: '2' },
      },
      {
        path: '/paidEventBillboard',
        name: 'paidEventBillboard',
        component: () => import('@/views/paidEventBillboard/index.vue'),
        meta: { title: '付费事件看板', pathName: '付费事件看板', parent: 'revenueKanban' },
      },
      {
        path: '/whitelistManage',
        name: 'whitelistManage',
        component: () => import('@/views/payManage/whitelistManage'),
        meta: { title: '验单白名单', pathName: '支付管理', parent: 'payManage' },
      },
      {
        path: '/refundApproval',
        name: 'refundApproval',
        component: () => import('@/views/orderManage/refundApproval'),
        meta: { title: '退款审批', pathName: '订单管理', parent: 'orderManage' },
      },
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('@/views/home/logoutPage'),
    meta: { title: '登出' },
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error'),
    meta: { title: '错误' },
  },
  {
    path: '/accountManageNew',
    name: 'accountManageNew',
    component: () => import('@/views/accountManageNew'),
    meta: { title: '主体管理' },
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
    // 动态处理页面缓存
	// 需求: 从列表进入详情页时(name => name), 或者详情页返回列表时,缓存列表页的状态
	const routeKeepAliveMap = {
		goodsSetting: 'goodsSettingDetail',
    goodsJudge: 'goodsSettingDetail',
    thirdPartyBill: 'thirdPartyBillDetail',
	};
	const rtStore = useStore();
    rtStore.setCachedName('goodsJudge')
	if (routeKeepAliveMap[from.name]?.includes(to.name)) {
		rtStore.setCachedName([from.name]);
	} else if (routeKeepAliveMap[to.name]?.includes(from.name) && from.name) {
		rtStore.setCachedName([to.name]);
	} else {
		rtStore.setCachedName([]);
	}
	next();
});


// 这里主要是适配子应用的单独访问和继承访问

export default router

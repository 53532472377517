import "core-js/modules/es.array.push.js";
/**
 * Create by zhuzhaoqing
 * Description: index
 * Date: 2023/3/31 10:22
 **/
import { defineStore } from 'pinia';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import router from '@/router';
import { setFrontUrlFetch, ssoLoginFetch, ssoLogoutFetch } from '@/api/login';
import { awaitWrap, reportEvent, reportInit } from "@/common";
export const useStore = defineStore('main', () => {
  const userInfo = ref(sessionStorage.getItem('userInfo') || '{"menu":[]}'),
    isCollapsed = ref(false),
    cachedName = ref([]); // KEEPALIVE缓存的组件name合集
  const NoticeInfo = ref();
  const handleMenu = menuStr => {
    var _JSON$parse;
    let menu = (_JSON$parse = JSON.parse(menuStr)) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.menu;
    let parentMenu = [];
    menu === null || menu === void 0 || menu.forEach(item => {
      if (!item.parentId) {
        parentMenu.push({
          ...item,
          children: []
        });
      }
    });
    menu === null || menu === void 0 || menu.forEach(item => {
      parentMenu.forEach(parentItem => {
        if (item.parentId === parentItem.menuId) {
          parentItem.children.push(item);
        }
      });
    });
    return parentMenu;
  };
  const menuData = ref(handleMenu(userInfo.value));
  const setCachedName = n => {
    cachedName.value = n || [];
  }; // 设置页面缓存
  //公告信息
  const setNoticeInfo = obj => {
    NoticeInfo.value = obj;
  };
  const ssoLogin = async () => {
    if (process.env['NODE_ENV'] === 'development' && process.env['VUE_APP_ISJOINT'] === 'false') {
      const param = {
        frontUrl: `${process.env.VUE_APP_FRONT_URL}`,
        frontErrUrl: `${process.env.VUE_APP_FRONT_ERRURL}`,
        frontLogoutUrl: `${process.env.VUE_APP_FRONT_LOGOUTURL}`
      };
      const [err, data] = await awaitWrap(setFrontUrlFetch(param));
      if (data) await ssoLoginAfter(); // 前置修改成功
      if (err) {} // 错误处理
    } else {
      await ssoLoginAfter();
    }
  };
  const ssoLoginAfter = async () => {
    try {
      let {
        code,
        activeUserInfo
      } = await ssoLoginFetch();
      if (code === '0000') {
        var _activeUserInfo$menu;
        reportInit(activeUserInfo);
        if ((_activeUserInfo$menu = activeUserInfo.menu) !== null && _activeUserInfo$menu !== void 0 && _activeUserInfo$menu.length) {
          userInfo.value = JSON.stringify(activeUserInfo);
          menuData.value = handleMenu(JSON.stringify(activeUserInfo));
          if (!sessionStorage.getItem('userInfo')) {
            var _menuData$value, _menuData$value2;
            router.push({
              name: (_menuData$value = menuData.value) === null || _menuData$value === void 0 || (_menuData$value = _menuData$value[0]) === null || _menuData$value === void 0 || (_menuData$value = _menuData$value['children']) === null || _menuData$value === void 0 || (_menuData$value = _menuData$value[0]) === null || _menuData$value === void 0 ? void 0 : _menuData$value['menuAdr']
            });
            reportEvent({
              eventName: 'ui_open',
              properties: {
                ui_name: `${(_menuData$value2 = menuData.value) === null || _menuData$value2 === void 0 || (_menuData$value2 = _menuData$value2[0]) === null || _menuData$value2 === void 0 || (_menuData$value2 = _menuData$value2['children']) === null || _menuData$value2 === void 0 || (_menuData$value2 = _menuData$value2[0]) === null || _menuData$value2 === void 0 ? void 0 : _menuData$value2['menuName']}页面打开`
              }
            });
          }
          sessionStorage.setItem('userInfo', JSON.stringify(activeUserInfo));
        } else {
          router.push({
            name: 'error',
            query: {
              code: '9001'
            }
          });
          reportEvent({
            eventName: 'ui_open',
            properties: {
              ui_name: `error页面打开`
            }
          });
        }
      }
    } catch (e) {}
  };
  const ssoLogout = async () => {
    try {
      sessionStorage.removeItem('userInfo');
      await ssoLogoutFetch();
      await ssoLogin();
    } catch (e) {}
  };
  const changeCol = () => {
    isCollapsed.value = !isCollapsed.value;
  };
  return {
    NoticeInfo,
    userInfo,
    menuData,
    isCollapsed,
    cachedName,
    setNoticeInfo,
    ssoLogout,
    ssoLogin,
    setCachedName,
    changeCol
  };
});
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
dayjs.locale('zh-cn');
import { defineComponent } from "vue";
export default defineComponent({
  name: "App",
  data() {
    return {
      locale: zhCN,
      data: null,
      theme: {
        token: {
          colorPrimary: '#2E1EEA'
        }
      }
    };
  }
});
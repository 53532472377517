// import { message } from "ant-design-vue";
import axios from 'axios'
import qs from 'qs'
import { message } from 'ant-design-vue'
import { Base64 } from 'js-base64'

// 使用VUE_APP_ISJOINT控制是否联调
let baseURLlocal = process.env.VUE_APP_DOMAIN
if (process.env.VUE_APP_ISJOINT === 'true' && process.env.NODE_ENV === 'development') {
  baseURLlocal = ''
}

axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

axios.interceptors.request.use(
  (config) => {
    // if (token) {
    //     config.headers = { ...config.headers, 'X-Request-req-token': token, 'X-Request-req-uid': uid }
    // }
    return config
  },
  (err) => {
    return Promise.reject(err)
  },
)

axios.interceptors.response.use(
  (res) => {
    // token = res.headers?.['x-request-req-token']
    // uid = res.headers?.['x-request-req-uid']
    if (res.headers.location) {
      window.location.href = res.headers.location
    }
    if (!['0', '0000'].includes(res?.data?.code) && res?.data?.msg) {
      message.error(res?.data?.msg || '服务器异常' + res.data?.code)
    }
    return res.data
  },
  (error) => {
    if (error.response && error.response?.status === 401 && error.response.headers.location) {
      sessionStorage.removeItem('userInfo')
      window.location.href = error.response.headers.location
    }
    if (error.response?.status === 500) {
      message.error('服务端接口报错')
    }
    if (Object.keys(error)[0] === 'message') {
      return { code: '6003' } //取消的请求 不报错的处理
    } else {
      return Promise.reject(error)
    }
  },
)
export default async (url, params = {}, method, data = {}, timeout = 180000, baseURL = baseURLlocal) => {
  return axios({
    url,
    method: method.split('_')[0],
    // `baseURL ` 将自动加在url前面，除非url是一个绝对url,
    // 它可以通过设置一个 baseURL 便于为 axios实例的方法传递相对URL
    baseURL,

    // `transformRequest` 允许在向服务器发送前，修改请求数据
    // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
    // 后面数组中的函数必须返回一个字符串，或 ArrayBuffer，或 Stream
    transformRequest: [
      function (data) {
        // 可对data进行任意转换
        // console.log(data,'transformRequest')
        // console.log(data, "qs.stringify(data)");
        if (method === 'POST' || method === 'POST_DOWNLOAD_FORM') {
          return qs.stringify(data)
        } else if (method === 'POST_FILE') {
          return data
        } else {
          return JSON.stringify(data)
        }

        // return JSON.stringify(data);
      },
    ],
    //在传递给 then/catch之前，允许修改响应数据
    transformResponse: [
      function (data) {
        // 对 data 进行任意转换;
        return data
      },
    ],
    headers: getHeader(method),
    params,
    // data 是作为请求主体被发送的数据
    // 只适用于这些请求方法 PUT POST PATCH
    // 在没有设置 transformRequest 时，必须是以下类型之一： string plan object, ArrayBuffer , ArrayBufferView , URLSearchParams
    // 浏览器专属  ： FormData , File , Blob;
    // Node 专属： Stream
    data,
    timeout,
    // 服务器响应的数据类型， 可以是‘arraybuffer','blob', 'documnet','json','text','stream'
    responseType: getResponseType(method), // default
    responseEncoding: 'utf8', // default
    // `onUploadProgress` 允许为上传处理进度事件
    // onUploadProgress: function (progressEvent) {
    // console.log(progressEvent,'传处理进度事件')
    // Do whatever you want with the native progress event
    // },

    // `onDownloadProgress` 允许为下载处理进度事件
    // onDownloadProgress: function (progressEvent) {
    // console.log(progressEvent,'下载处理进度事件')
    // 对原生进度事件的处理
    // },
  })
}

export function getHeader(method) {
  switch (method) {
    case 'GET':
      return {
        'Content-Type': 'application/json',
      }
    case 'POST':
      return {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }
    case 'POST_JENKENS':
      return {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Jenkins-Crumb': 'db624980a09769403934955a275b589a38cdb7ca88840df244f0b4662ca16efa',
        Authorization: `Basic ${Base64.encode('admin:118e2d04babc5231bf233512cd3b050248')}`,
        Accept: 'text/javascript, text/html, application/xml, text/xml, */*',
      }
    case 'POST_DOWNLOAD':
      return {
        responseType: 'blob',
        'Content-Type': 'application/json',
      }
    case 'POST_DOWNLOAD_FORM':
      return {
        responseType: 'blob',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      }
    case 'POST_FILE':
      return {
        'Content-Type': 'multipart/form-data',
      }
    case 'GET_LOGIN':
      return {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      }
    case 'POST_JSON':
    case 'POST_IMAGE':
    default:
      return {
        'Content-Type': 'application/json',
      }
  }
}

export function getResponseType(method) {
  switch (method) {
    case 'POST_DOWNLOAD_FORM':
    case 'POST_IMAGE':
    case 'POST_DOWNLOAD':
      return 'blob'
    case 'POST_JENKENS':
      return 'text'
    default:
      return 'json'
  }
}

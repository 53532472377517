import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
const _hoisted_1 = {
  class: "page-bg"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view");
  const _component_a_config_provider = _resolveComponent("a-config-provider");
  return _openBlock(), _createBlock(_component_a_config_provider, {
    locale: _ctx.locale,
    theme: _ctx.theme
  }, {
    default: _withCtx(() => [_createVNode(_component_router_view, null, {
      default: _withCtx(({
        Component
      }) => [_createVNode(_Transition, {
        name: "fade-transform",
        mode: "out-in"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))])]),
        _: 2
      }, 1024)]),
      _: 1
    })]),
    _: 1
  }, 8, ["locale", "theme"]);
}
/**
 * Create by zhuzhaoqing
 * Description: login
 * Date: 2023/3/31 10:12
 **/
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 单点登录
export const ssoLoginFetch = () => request('/payAdminServer/getActiveUser', null, 'GET', null)

// 退出登录
export const ssoLogoutFetch = () => request('/payAdminServer/logout', null, 'GET', null)

// 登录前提交前端地址
export const setFrontUrlFetch = (param: { frontUrl: string; frontErrUrl: string; frontLogoutUrl: string }) =>
  request('/payAdminServer/setFrontUrl', param, 'GET_LOGIN')
